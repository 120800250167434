<template>
  <section id="check">
    <div class="title_info">
      <p>
        {{ ccName }}
      </p>
    </div>
    <div class="join_wrap">
      <p
        class="stitle stitle_small"
        v-html="$t('commonMessage.enterCellphoneNumber', [checkInName])"
      />
      <div class="input_wrap">
        <input-phone-number
          ref="phoneNumber"
          :pure-phone-number.sync="phoneNumber"
          autofocus
          @submit="onKeyDownEnterPhoneNumber"
        />
        <span class="ico_delete" @click="onClickClear" />
      </div>
    </div>
    <div class="ft_btn">
      <a class="btn_point" @click="onClickSubmit">
        {{ $t("common.submit") }}
      </a>
    </div>
  </section>
</template>

<script>
import {
  saveEncAndRedirectAndPhoneNumber,
  removeEncAndRedirectAndPhoneNumber,
} from "@/utils/session/SessionUtil";
import InputPhoneNumber from "@/components/common/InputPhoneNumber";
import ProjectService from "@/service/ProjectService";
import ProjectAPIError from "@/api/error/ProjectAPIError";
import { mapGetters } from "vuex";

export default {
  name: "SignIn",
  components: { InputPhoneNumber },
  data() {
    return {
      enc: null,
      redirect: null,
      phoneNumber: this.$t("commonPlaceholder.contactTelPrefix"),
    };
  },
  async created() {
    const { enc, redirect } = this.$route.query;
    this.enc = enc;
    this.redirect = redirect;
    removeEncAndRedirectAndPhoneNumber();
  },
  computed: {
    ...mapGetters(["ccName", "checkInName"]),
  },
  methods: {
    async onKeyDownEnterPhoneNumber() {
      await this.onClickSubmit();
    },
    onClickClear() {
      this.clearPhoneNumber();
      this.$refs.phoneNumber.focusIn();
    },
    async onClickSubmit() {
      const phoneNumber = this.phoneNumber.replace(/-/gim, "");
      if (phoneNumber.length < 10) {
        await this.confirm({
          content: this.$t("commonMessage.enterCellphoneNumberAll"),
          submitCallback: () => this.$refs.phoneNumber.focusIn(),
        });
        return;
      }
      try {
        saveEncAndRedirectAndPhoneNumber({
          enc: this.enc,
          redirect: this.redirect,
          phoneNumber: this.phoneNumber,
        });
        await ProjectService.signIn({
          enc: this.enc,
          phoneNumber,
        });
        await this.$router.push(this.redirect);
      } catch (e) {
        if (e instanceof ProjectAPIError) {
          const { status } = e.data;
          switch (status) {
            case "INVALID_URL":
              this.clearPhoneNumber();
              break;
          }
        }
        throw new Error(e);
      }
    },
    clearPhoneNumber() {
      this.$refs.phoneNumber.clear();
    },
  },
};
</script>
